<template>
  <div>
    <b-row class="mb-2">
      <b-col md="12" style="display: flex; gap:10px;">
        <export-button
          :filter="exportFilter"
          url="/sportsbook/export-ticketStatus"
          :disabled="loadingData || !items.length"
          file-title="Sports report"
        />
        <b-button v-b-modal.modal-hierachy v-if="width<768">
          <feather-icon icon="GitPullRequestIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <!-- FILTER START -->
      <b-row>
        <b-col
          md="3"
          class="d-flex align-items-center justify-content-center mb-1"
        >
          <b-form-group :label="$t('labels.ticket_id')">
            <b-form-input
              v-model="filterSelect.ticketId"
              class="d-inline-block mr-1"
              :placeholder="$t('labels.ticket_id')"
              @input="inputSelect"
            />
          </b-form-group>
          <div style="padding-left:10px;">
            <b-button
              variant="primary"
              type="button"
              class="btn btn-icon rounded-circle mr-1 btn-primary btn-sm"
              v-b-tooltip.hover.top="$t('labels.search')"
              @click="modalSearchTicketId()"
              size="sm"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.status')">
            <v-select
              :value="$store.value"
              v-model="filterSelect.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              label="key"
              @input="inputSelect"
              :reduce="(status) => status.value"
              :placeholder="$t('select_status')"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.typeuser')">
            <v-select
              :value="$store.value"
              v-model="filterSelect.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              label="key"
              @input="inputSelect"
              :reduce="(status) => status.value"
              :placeholder="$t('select_type')"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.datefrom')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateFrom"
              @input="inputSelect"
              class="form-control"
              :config="{
                          altInput: true,
                          altFormat: 'd-m-Y h:i K',
                          enableTime: true,
                          wrap : true,
                          plugins: [
                             new yearDropdownPlugin({
                               yearStart: 100,
                               yearEnd: 0
                            })
                           ],
                        }"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.dateto')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateTo"
              @input="inputSelect"
              class="form-control"
              :config="{
                          altInput: true,
                          altFormat: 'd-m-Y h:i K',
                          enableTime: true,
                          wrap : true,
                          plugins: [
                             new yearDropdownPlugin({
                               yearStart: 100,
                               yearEnd: 0
                            })
                           ],
                        }"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- FILTER END -->
    </b-card>
    <b-row>
      <b-col md="10">
        <b-card>
          <b-overlay :show="loadingData" variant="transparent" no-wrap />
          <b-modal
            id="modalResponse"
            title="PopUp Response"
            cancel-variant="outline-secondary"
            scrollable
          >
            <b-card-text>
              {{ rows }}
            </b-card-text>
          </b-modal>
          <!-- search -->
          <b-col
            md="3"
            class="d-flex align-items-center justify-content-center mb-1"
          >
            <b-form-input
              v-model="search"
              class="d-inline-block mr-1"
              :placeholder="$t('filter')"
            />
          </b-col>
          <!-- table -->
          <div class="tab__content">
            <b-table
              md="9"
              :items="items"
              :fields="fields"
              class="scroll"
              responsive
            >
              <template #cell(bet)="data">
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'apps-sports-detail', params: { id: data.item.id } }"
                >
                  {{
                  data.item.bet
                  }}</b-button
                >
              </template>
              <template #cell(amount)="data">
                {{ data.item.amount | currency({ symbol: "" }) }}
              </template>
              <template #cell(finalBonusAmount)="data">
                {{ data.item.finalBonusAmount | currency({ symbol: "" }) }}
              </template>
              <template #cell(created)="data">
                {{ data.item.created | moment }}
              </template>
              <template #cell(status)="data">
                <span
                  v-if="data.item.status == 'PENDING'"
                  style="width: %100; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #03a9f4; color:#FFFFFF;"
                >
                  {{ $t('status.pending') }}</span
                >
                <span
                  v-else-if="data.item.status == 'LOST'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;"
                >
                  {{ $t('status.lost') }}</span
                >
                <span
                  v-else-if="data.item.status == 'WON'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;"
                >
                  {{ $t('status.won') }}</span
                >
                <span
                  v-else-if="data.item.status == 'NULL_BET'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;"
                >
                  {{ $t('status.null_bet') }}</span
                >
                <span
                  v-else-if="data.item.status == 'PAID'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;"
                >
                  {{ $t('status.paid') }}</span
                >
                <span
                  v-else-if="data.item.status == 'EXPIRED'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;"
                >
                  {{ $t('status.expired') }}</span
                >
                <span
                  v-else-if="data.item.status == 'RETURN'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;"
                >
                  {{ $t('status.return') }}</span
                >
                <span
                  v-else-if="data.item.status == 'INGAME'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #ffb300; color:#FFFFFF;"
                >
                  {{ $t('status.ingame') }}</span
                >
              </template>
            </b-table>
            <b-table-simple
              v-if="dataItems.length > 0"
              class="mt-2"
              hover
              small
              caption-top
              responsive
            >
              <b-thead style="text-align: center">
                <b-th style="width: 10%">{{ $t('totals') }}</b-th>
                <b-th style="width: 10%"></b-th>
                <b-th style="width: 10%"></b-th>
                <b-th style="width: 10%"></b-th>
                <b-th style="width: 10%"></b-th>
                <b-th
                  style="width: 10%"
                  >{{ Totals.amount | currency({ symbol: "" }) }}</b-th
                >
                <b-th style="width: 10%"
                  >{{ Totals.finalBonusAmount | currency({ symbol: "" }) }}
                </b-th>
              </b-thead>
            </b-table-simple>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >{{ $t('labels.showing') }} {{ "-" }} {{ $t('labels.to') }}
                  {{
                "-"
                  }}
                  {{ $t('labels.of') }} {{ "-" }}
                  {{ $t('labels.entries') }}</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="serverParams.page"
                  :total-rows="totalRows"
                  :per-page="serverParams.limit"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  :simple="true"
                  :hide-goto-end-buttons="true"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-modal
        id="modal-hierachy"
        hide-footer
        :title="$t('agent_list')"
        v-if="width<768"
      >
        <HierarchyUsers
          @selectUser="selectUserHierarchy"
          ref="HerarchiComponent"
        />
      </b-modal>
      <b-col class="p-0" md="2" v-else>
        <b-card no-body class="p-0 py-1">
          <HierarchyUsers @selectUser="showData" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import axios from '@/libs/axios';
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
import ExportButton from "@/views/pages/export/ExportButton.vue";

export default {
  components: {
    ExportButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    ToastificationContent,
    HierarchyUsers
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      loadingData: false,
      dataItems: [],
      Totals: {},
      dataIdUser: [],
      pages: [20, 40, 60],
      search: "",
      rows: [],
      serverParams: {
        page: 1,
        limit: 20,
      },
      totalRows: 0,
      filterSelect: {
        ticketId: "",
        status: "",
        type: "",
        dateFrom: moment().subtract(7, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      dataReportHistory: [],
      exportFilter: {},
      width: window.innerWidth,
    };
  },
  computed: {
    typeOptions() {
      return [
        { key: this.$t('tabs.agent'), value: "Agent" },
        { key: this.$t('tabs.player'), value: "Player" }
      ]
    },
    statusOptions(){
      return  [
        { key: this.$t('status.paid'), value: "PAID" },
        { key: this.$t('status.pending'), value: "PENDING" },
        { key: this.$t('status.ingame'), value: "INGAME" },
        { key: this.$t('status.win'), value: "WON" },
        { key: this.$t('status.expired'), value: "EXPIRED" },
        { key: this.$t('status.null_bet'), value: "NULL_BET" },
        { key: this.$t('status.lose'), value: "LOST" }
      ]
    },
    fields(){
      return [
        { label: this.$t('origin'), key: "source" },
        { label: this.$t('labels.ticket_id'), key: "bet" },
        { label: this.$t('labels.userName'), key: "userName" },
        { label: this.$t('labels.date'), key: "created" },
        { label: this.$t('labels.status'), key: "status" },
        { label: this.$t('betted_amount'), key: "amount" },
        { label: this.$t('amount_to_be_charged'), key: "finalBonusAmount" },
      ]
    },
    items() {
      return this.dataItems.filter(item => {
        return this.checkUsername(item) || this.checkTicket(item);
      })
    },
  },
  // add filters
  filters: {
    moment: function (date) {
      return moment(date).format("MM/DD/YYYY HH:mm:ss");
    },
  },

  methods: {
    yearDropdownPlugin,
    checkUsername(item) {
      return item.userName.toLowerCase().includes(this.search.toLowerCase())
    },
    checkTicket(item) {
      return item.bet.toLowerCase().includes(this.search.toLowerCase())
    },
    /*List - Index*/
    async showData({ id }) {
      this.dataIdUser = id
      try {
        this.loadingData = true;
        const VUE_APP_URL = process.env.VUE_APP_URL

        let date =  { dateFrom: new Date(this.filterSelect.dateFrom), dateTo: new Date(this.filterSelect.dateTo) }
        const params = {
          "currency": this.$store.state.whitelabelCurrencyNabvar.currency,
          "id": id,
          "whitelabel": this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
          "type": (this.filterSelect.type == '') ? '' : this.filterSelect.type,
          "status": (this.filterSelect.status == '') ? '' : this.filterSelect.status,
          "dateFrom": date.dateFrom.toISOString(),
          "dateAt": date.dateTo.toISOString()
        }
        this.exportFilter = params
        const result = await axios.post(`${VUE_APP_URL}/sportsbook/ticketStatus`, params)
        console.log("INFO: ",result.data);
        console.log(typeof result.data.data)
        this.dataItems = result.data.data
        this.Totals = result.data.totals

        return result

      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },

    modalSearchTicketId() {

      try {
        this.loadingData = true;
        const VUE_APP_URL = process.env.VUE_APP_URL

        const params = {
          whitelabel: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id
        }

        const result = axios
          .post(`${VUE_APP_URL}/sportsbook/` + this.filterSelect.ticketId + `/details`, params)
          .then(r => {
            const searchId = r.data.SportsBookPlay.response.id
            this.$router.push({ name: 'apps-sports-detail', params: { id: searchId } });
          })
          .catch(error => {
            console.log('Error Encontrado:', error.response)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching ticket",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })

        return result

      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching ticket",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },



    async selectUserHierarchy(id) {
      const currency = this.$store.state.whitelabelCurrencyNabvar.currency
      const whitelabel = 248
      const VUE_APP_URL = process.env.VUE_APP_URL
      const params = {
        "id": id,
        "currency": currency,
        "whitelabel": whitelabel
      }
      let result = await axios
        .post(`${VUE_APP_URL}/treeUsers`, params)
        .then(r => r.data == "" ? 0 : r.data)
        .catch(error => console.log(error.response))
      return result

    },
    onPageChange(page) {
      console.log(page, "page");
      this.serverParams.page = page;
      this.showData();
    },
    onPerPageChange(limit) {
      this.serverParams.page = 1;
      this.serverParams.limit = limit;
      this.showData();
    },
    inputSelect() {
      let id = this.dataIdUser

      this.showData({ id });
    },
  },
  created() {
    this.width = window.innerWidth;
    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });
  },
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
  display: none;
}

.scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
